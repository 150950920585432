@charset "UTF-8";

@import "../mixin";

.sub-main {
  height: 220px;
  line-height: 0;
  display: block;
  @include sp {
    height: 100px;
    min-height: 100px;
    width: 100vw;
    display: block;
    line-height: 1;
  }
  &-about {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-introduction {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-life_day {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-life_event {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-life_prekindergarten {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-life_keep {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-information {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-recruit {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-privacy {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
  &-news {
    background: url("../../../images/sub/sub-main-about.jpg") no-repeat center top;

    background-size: cover;
  }
}

.sub-contents {
  padding: 40px 0 0 0;
  background: url("../../../images/sub/sub-contents-bg.png") no-repeat center top -25px;
  color: $colorBrown;

  @include sp {
    background: url("../../../images/sub/sub-contents-bg-sp.png") no-repeat center top -20px;
    background-size: 430px;
    padding: 30px 0 0;
    overflow: hidden;
  }
  &--ttl {
    margin: 0 auto 30px;

    @include sp {
      margin-bottom: 15px;
    }
  }
  &--ttl_img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    @include sp {
      width: auto;
      height: 100px;
    }
  }
}

.sub-about {
  margin: 0 auto 80px;

  @include sp {
    margin-bottom: 40px;
  }
  &--headline {
    margin: 0 auto 30px;

    @include sp {
      margin-bottom: 15px;
    }
  }
  &--headline_img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  &--description {
    font-size: 16px;
    line-height: 2;
    text-align: center;

    @include sp {
      font-size: 14px;
      text-align: left;
      br {
        display: none;
      }
    }
  }
  &--rule {
    display: block;
    margin: 20px auto;
  }
}

.sub-greet {
  &--contents {
    width: calc(100% - 350px);
    position: relative;

    @include sp {
      width: 100%;
    }
  }
  &--person {
    display: block;
    position: absolute;
    top: 0;
    right: -350px;

    @include sp {
      margin: 0 auto 15px;
      position: static;
    }
  }
  &--description {
    margin: 0 0 30px 0;
    text-align: left;

    @extend .sub-about--description;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--headline {
    margin: 0 0 10px 0;
    font-size: 22px;
    line-height: 1.8;

    @include sp {
      font-size: 18px;
    }
  }
}

.sub-feature {
  &--headline {
    background: url('/images/sub/sub-feature-headline-bg.png') no-repeat;
    width: 764px;
    height: 100px;
    display: block;
    margin: 0 auto 50px;
    text-align: center;
    line-height: 105px;
    font-size: 26px;
    @include sp {
      width: 100%;
      height: auto;
      font-size: 22px;
      line-height: 1.5;
      background: none;
      padding: 10px 0;
      margin-bottom: 30px;
    }
  }
  &--content {
    display: flex;
    justify-content: space-between;
    margin: 0 0 80px 0;
    &:nth-child(odd) {
      flex-direction: row-reverse;
      .sub-feature {
        &--pic {
          margin-right: -135px;
        }
      }
    }
    @include sp {
      margin-bottom: 40px;
      display: block;
    }
  }
  &--txt_box {
    width: 630px;
    @include sp {
      width: 100%;
      font-size: 14px;
      br {
        display: none;
      }
    }
  }
  &--pic {
    position: relative;
    margin: 0 0 0 -135px;
    height: 323px;
    max-width: 100%;
    @include sp {
      display: block;
      height: auto;
      margin: 0 0 15px 0;
    }
  }
  &--topic {
    @extend .sub-greet--headline;
  }
  &--description {
    line-height: 2;
  }
}

.sub-detail {
  &--content {
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    padding: 40px 60px;
    @include sp {
      padding: 20px 15px;
    }
  }
  &--map {
    width: 100%;
    height: 400px;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
  }
  &--link_btn {
    color: #fff;
    background-color: #f5911e;
    border-radius: 30px;
    height: 60px;
    line-height: 60px;
    text-decoration: none;
    display: block;
    margin: 40px auto 0;
    max-width: 370px;
    text-align: center;
    font-size: 18px;
    transition: 0.15s;
    &:hover {
      background-color: darken(#f5911e, 15%);
    }
    @include sp {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
    }
  }
}

.sub-detail_list {
  border-bottom: 1px dotted #ccc;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  &:first-child {
    padding-top: 0;
  }
  @include sp {
    padding: 10px 0;
    text-align: center;
  }
  &--ttl {
    background: url('../../../images/sub/sub-detail-list-ttl-bg.png') no-repeat;
    width: 124px;
    height: 29px;
    background-size: cover;
    color: #fff;
    text-align: center;
    line-height: 29px;
    margin: 0 30px 0 0;
    @include sp {
      line-height: 20px;
      height: 20px;
      width: 89px;
      font-size: 12px;
      margin: 0 auto 5px auto;
      display: block;
      text-align: center;
    }
  }
  &--description {
    height: 29px;
    line-height: 29px;
    @include sp {
      font-size: 14px;
      line-height: 1.3;
      height: auto;
      display: block;
      width: 100%;
    }
  }
}

.sub-header_life_nav {
  background-color: #f5911e;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  @include sp {
    display: none !important;
  }
  &--link {
    height: 55px;
    line-height: 55px;
    color: #fff;
    text-decoration: none;
    display: block;
    width: 130px;
    text-align: center;
    transition: 0.15s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: darken(#f5911e, 10%);
    }
    &.current {
      background-color: darken(#f5911e, 10%);
    }
  }
}

.sub-life_nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 50px 0;
  @include sp {
    padding: 0 15px;
    margin-bottom: 30px;
  }
  &--link {
    text-decoration: none;
    display: block;
    margin: 0 15px 0 0;
    &:last-child {
      margin-right: 0;
    }
    @include sp {
      width: calc(50% - 10px);
      margin: 0 0 10px 10px;
      &:nth-child(1),&:nth-child(3) {
        margin-left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--link_img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.sub-life {
  &--headline {
    font-size: 26px;
    text-align: center;
    display: block;
    margin: 0 0 40px 0;
    @include sp {
      line-height: 1.5;
      font-size: 22px;
    }
  }
}

.sub-time_line {
  position: relative;
  width: 910px;
  margin: 0 auto 100px;
  @include sp {
    width: 100%;
    margin-bottom: 0;
  }
  &--content {
    width: 430px;
    height: 350px;
    padding: 55px 75px 55px 45px;
    position: relative;
    box-sizing: border-box;
    @include sp {
      width: 100%;
      background-size: contain !important;
      padding: 55px 30px;
      &:before {
        display: none !important;
      }
    }
    &-attend {
      background: url('../../../images/sub/sub-day-content-bg-green.png') no-repeat;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-green.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 49px;
      }
      @include sp {
        margin-bottom: 70px;
      }
      .sub-time_line {
        &--icon {
          right: 100px;
          top: -5px;
        }
        &--pic {
          left: -50px;
          bottom: -85px;
        }
        @include sp {
          &--icon {
            right: -10px;
          }
          &--pic {
            left: 0;
            bottom: -33px;
            width: 350px;
          }
        }
      }
    }
    &-free {
      margin: -110px 0 0 auto;
      background: url('../../../images/sub/sub-day-content-bg-yellow.png') no-repeat;
      right: -15px;
      &:before {
        content: "";
        display: block;
        width: 55px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-yellow.png') no-repeat;
        position: absolute;
        left: -20px;
        top: 67px;
      }
      @include sp {
        margin: 0 0 90px 0;
        right: 0;
      }
      .sub-time_line {
        &--icon {
          right: 5px;
          top: -145px;
        }
        &--pic {
          right: -15px;
          bottom: -65px;
        }
        @include sp {
          &--icon {
            width: 180px;
            top: auto;
            bottom: 0;
            right: auto;
            left: 0;
            z-index: 1;
          }
          &--pic {
            width: 230px;
            bottom: -15px;
            right: 0;
          }
        }
      }
    }
    &-physical {
      background: url('../../../images/sub/sub-day-content-bg-pink.png') no-repeat;
      margin-top: -110px;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-pink.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 66px;
      }
      @include sp {
        margin: 0 0 205px 0;
      }
      .sub-time_line {
        &--pic {
          left: -50px;
          bottom: -65px;
        }
        @include sp {
          &--pic {
            bottom: 10px;
            left: 0;
            width: 230px;
          }
        }
      }
    }
    &-morning_meet {
      margin: 0 0 0 auto;
      background: url('../../../images/sub/sub-day-content-bg-green.png') no-repeat;
      right: -15px;
      &:before {
        content: "";
        display: block;
        width: 40px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-green.png') no-repeat;
        position: absolute;
        left: 3px;
        top: 63px;
      }
      @include sp {
        margin: 0 0 170px 0;
        right: 0;
      }
      .sub-time_line {
        &--pic {
          right: -15px;
          bottom: -150px;
        }
        @include sp {
          &--pic {
            right: 0;
            bottom: -90px;
            width: 230px;
          }
        }
      }
    }
    &-primary {
      background: url('../../../images/sub/sub-day-content-bg-yellow.png') no-repeat;
      margin-top: -46px;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-yellow.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 66px;
      }
      @include sp {
        margin: 0 0 130px 0;
      }
      .sub-time_line {
        &--pic {
          left: -50px;
          bottom: -180px;
        }
        @include sp {
          &--pic {
            left: 0;
            bottom: -75px;
            width: 330px;
          }
        }
      }
    }
    &-ready {
      margin: 0 0 0 auto;
      background: url('../../../images/sub/sub-day-content-bg-pink.png') no-repeat;
      right: -15px;
      &:before {
        content: "";
        display: block;
        width: 40px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-pink.png') no-repeat;
        position: absolute;
        left: 3px;
        top: 67px;
      }
      @include sp {
        right: 0;
        margin: 0 0 50px 0;
      }
      .sub-time_line {
        &--pic {
          right: -15px;
          bottom: -80px;
        }
        @include sp {
          &--pic {
            right: 0;
            bottom: 0;
            width: 340px;
          }
        }
      }
    }
    &-provide {
      background: url('../../../images/sub/sub-day-content-bg-green.png') no-repeat;
      margin-top: -50px;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-green.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 66px;
      }
      @include sp {
        margin: 0 0 70px 0;
      }
      .sub-time_line {
        &--pic {
          left: -50px;
          bottom: -100px;
        }
        @include sp {
          &--pic {
            left: 0;
            bottom: -20px;
            width: 230px;
          }
        }
      }
    }
    &-free_night {
      margin: -70px 0 0 auto;
      background: url('../../../images/sub/sub-day-content-bg-yellow.png') no-repeat;
      right: -15px;
      &:before {
        content: "";
        display: block;
        width: 40px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-yellow.png') no-repeat;
        position: absolute;
        left: 3px;
        top: 67px;
      }
      @include sp {
        right: 0;
        margin: 0 0 70px 0;
      }
      .sub-time_line {
        &--pic {
          right: -15px;
          bottom: -70px;
        }
        @include sp {
          &--pic {
            right: 0;
            bottom: -20px;
            width: 230px;
          }
        }
      }
    }
    &-class {
      background: url('../../../images/sub/sub-day-content-bg-pink.png') no-repeat;
      margin-top: -30px;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-pink.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 66px;
      }
      @include sp {
        margin: 0 0 40px 0;
      }
      .sub-time_line {
        &--pic {
          left: -50px;
          bottom: -100px;
        }
        @include sp {
          &--pic {
            left: 0;
            bottom: -40px;
            width: 230px;
          }
        }
      }
    }
    &-return {
      margin: -70px 0 0 auto;
      background: url('../../../images/sub/sub-day-content-bg-green.png') no-repeat;
      right: -15px;
      &:before {
        content: "";
        display: block;
        width: 40px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-green.png') no-repeat;
        position: absolute;
        left: 3px;
        top: 67px;
      }
      @include sp {
        right: 0;
        margin: 0 0 70px 0;
      }
      .sub-time_line {
        &--pic {
          right: -15px;
          bottom: -50px;
        }
        @include sp {
          &--pic {
            right: 0;
            bottom: 0;
            width: 240px;
          }
        }
      }
    }
    &-home {
      background: url('../../../images/sub/sub-day-content-bg-yellow.png') no-repeat;
      &:before {
        content: "";
        display: block;
        width: 37px;
        height: 6px;
        background: url('../../../images/sub/sub-day-border-yellow.png') no-repeat;
        position: absolute;
        right: 25px;
        top: 66px;
      }
      .sub-time_line {
        &--pic {
          right: -165px;
          bottom: -130px;
        }
        @include sp {
          &--pic {
            right: 0;
            bottom: 30px;
            width: 100%;
          }
        }
      }
    }
  }
  &--border {
    position: absolute;
    left: calc(50% - 3px);
    top: 130px;
    width: 6px;
    height: 2390px;
    background-color: $colorBrown;
    border-radius: 10px;
    @include sp {
      top: 0;
      height: calc(100% - 100px);
    }
  }
  &--icon {
    position: absolute;
  }
  &--content_ttl {
    font-size: 22px;
    margin: 0 0 15px 0;
    @include sp {
      font-size: 22px;
      text-align: center;
    }
  }
  &--content_description {
    line-height: 1.8;
    font-size: 16px;
    @include sp {
      font-size: 14px;
    }
  }
  &--pic {
    position: absolute;
  }
  &--time {
    position: absolute;
    left: calc(50% - 43px);
    z-index: 1;
    &:before {
      content: "";
      display: block;
      background-color: #fafaf0;
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    @include sp {
      width: 60px;
      left: calc(50% - 30px);
      &:before {
        display: none;
      }
    }
    &-n1 {
      top: 15px;
      @include sp {
        top: -30px;
      }
    }
    &-n2 {
      top: 510px;
      @include sp {
        top: 830px;
      }
    }
    &-n3 {
      top: 860px;
      @include sp {
        top: 1385px;
      }
    }
    &-n4 {
      top: 1165px;
      @include sp {
        top: 1906px;
      }
    }
    &-n5 {
      top: 1517px;
      @include sp {
        top: 2388px;
      }
    }
    &-n6 {
      top: 1815px;
      @include sp {
        top: 2785px;
      }
    }
    &-n7 {
      top: 2096px;
      @include sp {
        top: 3205px;
      }
    }
    &-n8 {
      top: 2415px;
      @include sp {
        top: 3625px;
      }
    }
    &-n9 {
      top: 2417px;
      @include sp {
        top: 4008px;
      }
    }
    &-n10 {
      top: 3075px;
      @include sp {
        top: 4430px;
      }
    }
  }
  &--time_img {
    position: relative;
    max-width: 100%;
    height: auto;
  }
  &--time_pin {
    border: 4px solid $colorBrown;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    background-color: #fff;
    display: block;
    position: absolute;
    left: calc(50% - 14px);
    @include sp {
      z-index: 1;
    }
    &-n1 {
      top: 295px;
      @include sp {
        top: 420px;
      }
    }
    &-n2 {
      top: 750px;
      @include sp {
        top: 1230px;
      }
      .sub-time_line {
        &--time_pin_img {
          left: 30px;
          top: -30px;
        }
        @include sp {
          &--time_pin_img {
            left: -90px;
            top: 50px;
            width: 270px;
          }
        }
      }
    }
    &-n3 {
      top: 1430px;
      @include sp {
        top: 2130px;
      }
      .sub-time_line {
        &--time_pin_img {
          left: 30px;
          top: -60px;
        }
        @include sp {
          &--time_pin_img {
            width: 205px;
            left: -60px;
            top: 46px;
          }
        }
      }
    }
  }
  &--time_pin_img {
    position: absolute;
  }
}

.sub-event {
  &--ttl {
    position: relative;
    margin: 0 0 50px 0;
    @include sp {
      padding: 15px 0;
      margin: 0 0 20px 0;
    }
    &-pink {
      background: url('../../../images/sub/sub-event-ttl-bg-pink.png') left top;
    }
    &-green {
      background: url('../../../images/sub/sub-event-ttl-bg-green.png') left top;
    }
    &-blue {
      background: url('../../../images/sub/sub-event-ttl-bg-blue.png') left top;
    }
  }
  &--ttl_img {
    @include sp {
      width: 150px;
      height: auto;
      position: absolute;
      left: 15px;
      top: -10px;
    }
  }
  &--ttl_txt {
    text-align: center;
    color: $colorBrown;
    font-size: 25px;
    position: absolute;
    height: 117px;
    line-height: 117px;
    top: 0;
    left: 300px;
    @include sp {
      position: static;
      font-size: 18px;
      line-height: 1.5;
      text-align: left;
      height: auto;
      padding: 0 0 0 145px;
    }
  }
  &--content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 100px;
    justify-content: center;
    position: relative;
    max-width: 1000px;
    @include sp {
      display: block;
      padding: 0 15px;
      margin: 0 0 40px 0;
    }
    &-reverse {
      flex-direction: row-reverse;
    }
    &-last {
      margin-bottom: 0;
    }
  }
  &--content_pic {
    display: block;
    margin: 0 0 0 0;
    max-width: 100%;
    height: auto;
    @include sp {
      margin: 0 0 15px 0;
    }
  }
  &--schedule {
    margin: 20px 0 0 30px;
    @include sp {
      margin: 0;
    }
    &-reverse {
      margin: 20px 35px 0 0;
    }
  }
  &--schedule_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px 0;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      margin-bottom: 10px;
    }
  }
  &--schedule_ttl {
    background: url('../../../images/sub/sub-event-schedule-bg.png') no-repeat;
    background-size: cover;
    width: 77px;
    height: 39px;
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    margin: 0 15px 0 0;
    @include sp {
      width: 60px;
      height: 31px;
      background-size: contain;
      line-height: 31px;
      font-size: 20px;
    }
  }
  &--schedule_type {
    font-size: 18px;
    display: inline-block;
    margin: 0 0 0 -5px;
  }
  &--schedule_description {
    font-size: 16px;
    line-height: 1.7;
    margin: 5px 0 0 0;
    @include sp {
      font-size: 14px;
    }
  }
  &--note {
    text-align: left;
    font-size: 14px;
    width: 100%;
    max-width: 1000px;
    margin: 50px 0 0 0;
    @include sp {
      font-size: 14px;
      margin-top: 20px;
    }
  }
  &--icon {
    position: absolute;
    @include sp {
      display: none;
    }
    &-n1 {
      right: 70px;
      top: -90px;
    }
    &-n2 {
      right: 110px;
      top: 115px;
    }
    &-n3 {
      right: 20px;
      top: 352px
    }
    &-n4 {
      right: 304px;
      top: 342px;
    }
    &-n5 {
      left: 320px;
      top: 72px;
    }
    &-n6 {
      left: 63px;
      top: 205px;
    }
    &-n7 {
      right: 50px;
      top: 90px;
    }
    &-n8 {
      right: 100px;
      top: 370px;
    }
  }
}

.sub-prekindergarten {
  &--ttl {
    display: block;
    margin: 0 auto 50px;
    @include sp {
      margin-bottom: 30px;
    }
  }
  &--ttl_img {
    display: block;
    margin: 0 auto;
    height: auto;
    @include sp {
      margin-bottom: 0px;
      max-width: 100%;
    }
  }
  &--headline {
    font-size: 26px;
    text-align: center;
    position: relative;
    margin: 0 0 50px 0;
    &:before {
      content: '';
      border: 1px solid #42210b;
      width: 34px;
      position: absolute;
      left: 127px;
      top: 15px;
      transform: rotate(60deg);
    }
    &:after {
      content: '';
      border: 1px solid #42210b;
      width: 34px;
      position: absolute;
      right: 127px;
      top: 15px;
      transform: rotate(-60deg);
    }
    @include sp {
      font-size: 20px;
      text-align: left;
      line-height: 1.5;
      margin-bottom: 20px;
      &:before, &:after {
        display: none;
      }
    }
  }
  &--content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 50px 0;
  }
  &--description {
    font-size: 16px;
    line-height: 1.8;
    max-width: 790px;
    margin: 0 auto 30px;
    @include sp {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
  &--cms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 920px;
    margin: 0 auto 80px;
  }
}

.sub-prekindergarten_schedule {
  width: 440px;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 25px;
  box-sizing: border-box;
  @include sp {
    margin-bottom: 50px;
  }
  &--ttl {
    background: url('../../../images/sub/sub-prekindergarten-cms-bg.png') no-repeat;
    background-size: contain;
    width: 162px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin: -30px auto 15px;
  }
  &--note {
    text-indent: -1em;
    padding: 0 0 0 1em;
    margin: 0 0 15px 0;
    text-align: center;
    font-size: 14px;
    @include sp {
      font-size: 14px;
      text-align: left;
      line-height: 1.5;
      letter-spacing: -1px;
    }
  }
  &--box {
    background: url('../../../images/common/border.png') repeat-x left top;
    padding: 10px 0 0 0;
    margin: 0 0 20px 0;
  }
  &--list {
    border-bottom: 1px dotted #ccc;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
    @include sp {
      padding: 15px 10px;
    }
  }
  &--list_ttl {
    width: 110px;
    @include sp {
      font-size: 14px;
      width: 110px;
    }
  }
  &--list_description {
    color: #000;
    @include sp {
      font-size: 14px;
      display: block;
      width: 100%;
      margin-top: 10px;
    }
  }
}

.sub-prekindergarten_time_line {
  width: 440px;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 25px;
  box-sizing: border-box;
  height: 370px;
  &--ttl {
    @extend .sub-prekindergarten_schedule--ttl;
  }
  &--description {
    margin: 0 0 15px 0;
    text-align: center;
    @include sp {
      font-size: 14px;
      text-align: left;
      letter-spacing: -1px;
    }
  }
  &--note {
    font-size: 14px;
    text-indent: -1em;
    padding: 0 0 0 1em;
    line-height: 1.5;
    @include sp {
      font-size: 12px;
      line-height: 1.5;
    }
  }
}

.sub-inquiry {
  &--description {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px 0;
    @include sp {
      font-size: 14px;
    }
  }
  &--note {
    font-size: 14px;
    text-indent: -1em;
    padding: 0 0 0 1em;
    line-height: 1.5;
    @include sp {
      font-size: 12px;
    }
  }
  &--link_btn {
    background-color: #f5911e;
    color: #fff;
    font-size: 18px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-radius: 50px;
    display: block;
    margin: 40px auto 50px;
    max-width: 490px;
    text-decoration: none;
    transition: 0.15s;
    &:hover {
      background-color: darken(#f5911e, 10%);
    }
    @include sp {
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      margin: 30px 0;
    }
  }
}

.sub-inquiry_tel {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 25px;
  background: url('../../../images/sub/sub-inquiry-tel-bg.png') no-repeat;
  background-size: contain;
  height: 92px;
  align-items: center;
  box-sizing: border-box;
  @include sp {
    background: none;
    border: 1px dotted $colorBrown;
    border-radius: 5px;
    flex-direction: row-reverse;
    height: auto;
    padding: 15px 15px;
  }
  &--ttl {
    background-color: $colorBrown;
    color: #fff;
    border-radius: 50px;
    width: 210px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin: 0 30px 0 0;
    @include sp {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      width: 100%;
      margin: 0 0 0 0;
    }
  }
  &--tel {
    color: $colorBrown;
    font-size: 30px;
    position: relative;
    padding: 0 0 0 40px;
    margin: 0 20px 0 0;
    &:before {
      content: $iconTel;
      @include icon;
      font-size: 34px;
      position: absolute;
      left: 0;
      top: -2px;
    }
    @include sp {
      font-size: 25px;
      text-align: center;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 0 10px 0;
      &:before {
        position: relative;
        margin: 2px 5px 0 0;
        top: 4px;
      }
    }
    a {
      text-decoration: none;
      color: $colorBrown;
    }
  }
  &--note {
    font-size: 14px;
    @include sp {
      letter-spacing: -1.5px;
    }
  }
}

.information {
  &--content {
    margin: 0 0 50px 0;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      margin-bottom: 40px;
    }
  }
  &--headline {
    background: url('../../../images/sub/sub-information-content-bg.png') no-repeat;
    background-size: contain;
    width: 124px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    margin: 0 0 5px 0;
  }
}

.cms_list {
  background: url('../../../images/sub/sub-cms-list-border.png') repeat-x top left;
  padding: 6px 0 0 0;
  &--item {
    padding: 13px 15px;
    border-bottom: 1px dashed #cacaca;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    @include sp {
      font-size: 14px;
      display: block;
      padding: 10px 0;
    }
  }
  &--cat {
    flex: 0.2;
    @include sp {
      width: 22%;
      display: inline-block;
      font-weight: bold;
    }
    &-other {
      @include sp {
        display: block;
        width: 100%;
      }
    }
  }
  &--member {
    width: 75px;
    @include sp {
      width: 22%;
      display: inline-block;
    }
  }
  &--class_name {
    width: 100px;
    @include sp {
      width: 22%;
      display: inline-block;
    }
  }
  &--last_member {
    width: 75px;
    @include sp {
      width: 22%;
      display: inline-block;
    }
  }
  &--txt {
    flex: 1;
    line-height: 1.3;
    @include sp {
      width: 100%;
      display: block;
      margin-top: 10px;
    }
    &-long {
      @include sp {
        width: calc(100% - 80px);
        line-height: 1.5;
        padding-left: 80px;
      }
    }
  }
  &--note {
    font-size: 14px;
    text-indent: -1em;
    padding: 0 0 0 1em;
    margin: 10px 0 0 0;
    line-height: 1.5;
    @include sp {
      font-size: 12px;
    }
  }
}

.recruit {
  &--ttl {
    font-size: 30px;
    text-align: center;
    display: block;
    margin: 0 0 40px 0;
    @include sp {
      line-height: 1.5;
      font-size: 22px;
    }
  }
  &--content {
    margin: 0 0 50px 0;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      margin-bottom: 40px;
    }
  }
  &--headline {
    background: url(../../../images/sub/sub-information-content-bg.png) no-repeat;
    background-size: contain;
    width: 124px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    margin: 0 0 5px 0;
  }
}

.cms_recruit {
  background: url(../../../images/sub/sub-cms-list-border.png) repeat-x top left;
  padding: 6px 0 0 0;
  &--item {
    padding: 13px 15px;
    border-bottom: 1px dashed #cacaca;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  &--ttl {
    font-weight: bold;
    position: relative;
    line-height: 1.3;
    &:after {
      content: '：';
    }
    @include sp {
      width: 75px;
      margin: 0 5px 10px 0;
      font-size: 14px;
      line-height: 1.3;
      &:after {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  &--txt {
    color: #000;
    line-height: 1.3;
    flex: 1;
    @include sp {
      font-size: 14px;
    }
  }
  &--member {
    width: 100px;
    line-height: 1.3;
    @include sp {
      font-size: 14px;
    }
  }
  &--cat {
    width: 100px;
    line-height: 1.3;
    @include sp {
      font-size: 14px;
    }
  }
}

.cms_recruit_detail {
  background: url(../../../images/sub/sub-cms-list-border.png) repeat-x top left, url(../../../images/sub/sub-cms-list-border.png) repeat-x bottom left,;
  padding: 6px 0 0 0;
  &--item {
    border-bottom: 1px dashed #cacaca;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: flex-start;
    &:last-child {
      border-bottom: 0;
    }
  }
  &--ttl {
    display: block;
    width: 220px;
    line-height: 1.8;
    border-right: 1px dashed #cacaca;
    padding: 13px 15px;
    box-sizing: border-box;
    @include sp {
      width: 100px;
      font-size: 14px;
    }
  }
  &--txt {
    flex: 1;
    line-height: 1.8;
    padding: 13px 15px;
    @include sp {
      font-size: 14px;
    }
  }
}

.sub-single {
  &--ttl {
    background: url(../../../images/sub/sub-cms-list-border.png) repeat-x top left,url(../../../images/common/border.png) repeat-x bottom left;;
    padding: 40px 0 35px 0;
    font-size: 26px;
    text-align: center;
    color: $colorBrown;
    margin: 0 0 10px 0;
    @include sp {
      font-size: 20px;
      text-align: left;
      line-height: 1.5;
      padding: 25px 0 20px 0;
    }
  }
  &--date {
    color: #333;
    font-size: 16px;
    display: block;
    margin: 0 0 15px 0;
  }
  &--icatch {
    display: block;
    margin: 0 auto 30px;
    @include sp {
      max-width: 100%;
      height: auto;
    }
  }
  &--contents {
  }
  &--pdf_link {
    color: $colorBrown;
    text-decoration: underline;
    display: block;
    line-height: 1.8;
    background: url('../../../images/sub/icon-pdf.png') no-repeat left top 2px;
    background-size: 18px 22px;
    padding: 0 0 0 25px;
    @include sp {
      font-size: 14px;
    }
  }
  &--recommend_ttl {
    background: url(../../../images/sub/sub-information-content-bg.png) no-repeat;
    background-size: contain;
    width: 124px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    margin: 20px 0 10px 0;
    display: block;
  }
  &--recommend_item {
    background: url('../../../images/sub/icon-link.png') no-repeat left top 5px;
    background-size: 23px 22px;
    line-height: 2;
    padding: 0 0 0 28px;
  }
  &--recommend_link {
    color: $colorBrown;
    @include sp {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}

.sub-uniform {
  margin-bottom: 80px;
  @include sp {
    margin-bottom: 40px;
  }
  &--pic {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.sub-privacy {
  padding-top: 100px;
  &--ttl {
    font-size: 26px;
    color: $colorBrown;
    text-align: center;
    margin: 0 0 50px 0;
    @include sp {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  &--headline {
    color: $colorBrown;
    font-size: 18px;
    margin: 0 0 15px 0;
    @include sp {
      font-size: 16px;
    }
  }
  &--txt {
    font-size: 14px;
    line-height: 1.8;
    margin: 0 0 35px 0;
    @include sp {
      margin-bottom: 15px;
    }
  }
  &--link {
    color: $colorBrown;
  }
}

.sub-privacy_list {
  font-size: 14px;
  margin: -25px 0 30px 0;
  @include sp {
    margin-top: -10px;
  }
  &--item {
    text-indent: -1em;
    padding: 0 0 0 1em;
    margin: 0 0 10px 0;
    line-height: 1.5;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sub-keep {
  &--ttl {
    color: $colorBrown;
    font-size: 26px;
    text-align: center;
    margin: 0 0 40px 0;
    @include sp {
      font-size: 20px;
      margin-bottom: 15px;
      text-align: left;
      line-height: 1.5;
    }
  }
  &--description {
    font-size: 16px;
    line-height: 1.8;
    margin: 0 0 50px 0;
    @include sp {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}

.sub-keep_list {
  width: 510px;
  margin: 0 auto 50px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  @include sp {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 15px;
  }
  &--row {
    display: flex;
    border-bottom: 1px dashed #ccc;
    &:last-child {
      border-bottom: 0;
    }
  }
  &--box {
    width: 33.3%;
    border-right: 1px dashed #ccc;
    &:last-child {
      border-right: 0;
    }
  }
  &--headline {
    background: url(../../../images/sub/sub-information-content-bg.png) no-repeat;
    background-size: contain;
    width: 124px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    margin: 0 auto 5px auto;
    display: block;
    @include sp {
      width: 100%;
      height: auto;
      font-size: 14px;
      line-height: 2;
    }
  }
  &--txt {
    display: block;
    color: $colorBrown;
    text-align: center;
    font-size: 16px;
    margin: 15px 0;
    @include sp {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
.sub-keep_note_list {
  font-size: 12px;
  &--item {
    text-indent: -1em;
    padding: 0 0 0 1em;
    line-height: 1.5;
    margin: 0 0 5px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
